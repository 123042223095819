<div class="row">
    <div class="col-lg-4">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "publicaciones.titulo_update" | translate }}</h3>
      </div>
      <div class="card-body">
        <!--Lista idiomas-->
        <div class="clearfix">
          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>
      </div>
        <kendo-dropdownlist #dropdownI
        [data]="listaIdiomas"
        textField="nombre"
        valueField="codigo"
        [(ngModel)]="selectedIdioma"
        (valueChange)="loadNoticia($event, dropdownI)"
        ></kendo-dropdownlist>
        <!--Titulo-->
        <kendo-textbox
          placeholder="{{ 'publicaciones.titulo_publi' | translate }}"
          [clearButton]="true"
          [(ngModel)]="titulo"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <button kendoButton look="clear" icon="calendar"></button>
          </ng-template>
        </kendo-textbox>
        <!--Cuerpo-->
        <div class="form-group">
            <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
                <textarea kendoTextArea style="resize: none" rows="5" [(ngModel)]="cuerpo"> </textarea>
              </kendo-label>
          </div>
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
              <span>Select Image</span>
              <input #imageInput
                     type="file"
                     accept="image/*"
                     (change)="imagePreview($event)">
            </label>
            
            <div *ngIf="filePath && filePath !== ''">
              <img [src]="filePath" style="height: 200px;">
            </div>
          <!-- Publicacion Programada-->
          <div>
        
                  <kendo-label
                  class="k-checkbox-label"
                  text="{{ 'publicaciones.programada' | translate }}"
                ></kendo-label>
                <input type="checkbox"
                [(ngModel)]="isProgramada"
                kendoCheckBox />
  
                    <kendo-datetimepicker *ngIf="isProgramada" [format]="format" [(ngModel)]="fecha">
                    </kendo-datetimepicker>
  
         
          </div>
          <!--CheckBox Miembros-->
          <div>
              <kendo-label
              class="k-checkbox-label"
              text="{{ 'publicaciones.miembros' | translate }}"
            ></kendo-label>
            <input type="checkbox"
            [(ngModel)]="isMembersOnly"
            kendoCheckBox />
      </div>

                  <!-- Selección Enviar App-->
                  <kendo-multiselect
                  [autoClose]="true"
                  [filterable]="true"
                  (filterChange)="handleFilter($event)"
                  [data]="listaAppsFilter"
                  textField="nombre"
                  valueField="id"
                  [(ngModel)]="listaSeleccionados"
                  placeholder="Selecciona las Apps a las que enviar el contenido"
                ></kendo-multiselect>
  
        <!--Boton Publicar-->
        <button
          type="button"
          class="btn btn-success"
          (click)="btnActualizarPublicacion()"
        >
          {{ "publicaciones.actualizar" | translate }}
        </button>
        <button type="button"
        class="btn btn-danger btn-sm mr-1"
        (click)="btnCancelar()">
        {{ "botones.cancelar" | translate }}
      </button>
      </div>
    </div>
  </div>
  
