import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';

import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';
import { App } from '@app/_models/app';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
})
export class EventosComponent implements OnInit {
  listaPublicaciones: Noticia[] = [];
  public seleccionados: number[] = [];
  listaApps: App[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private eventosService: EventosService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('eventos.cabecera');

        this.eventosService
        .getAll()
        .subscribe((result) => {
          console.log(result);
          this.listaPublicaciones = result.data;
          console.log(
            'Eventos cargados: ' + this.listaPublicaciones.length
          );
        });
      }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }



  onClickNuevo() {
      this.router.navigate(['eventos-crear']);
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    if(this.seleccionados.length<=0)return;
    this.router.navigate(['eventos-editar',{idevento:this.seleccionados[0] }]);
  }

  cellClick(){
    this.router.navigate(['eventos-editar',{idevento:this.seleccionados[0] }]);
  }

  eliminarRegistro(contentloading) {
    console.log('Eliminar registro');
    let listaBorrar:number[]=[];
    listaBorrar = this.seleccionados;
    this.eventosService
      .delete({ codigos: this.seleccionados })
      .subscribe((data) => {
        if (data.error == false) {
          this.eventosService
            .getAll()
            .subscribe((result) => {
              this.listaPublicaciones = result.data;
            });
          //Borrando de Apps
          listaBorrar.forEach((idNoticia) => {
            console.log('Borrando Evento: '+ idNoticia);
            this.eventosService
              .getRegistroPublicacion(idNoticia)
              .subscribe((resultTmp) => {
                console.log(resultTmp);
                this.listaApps = resultTmp.data;
                if (this.listaApps!=null && this.listaApps.length > 0) {
                  this.listaApps.forEach((app) => {
                    console.log('Borrando ' + app.nombre);
                    this.eventosService
                      .deleteExt(idNoticia, app)
                      .subscribe((resultDeleteExt) => {
                        if (!resultDeleteExt.error) {
                          console.log('Borrada de ' + app.nombre);
                        } else {
                          console.log('Error borrando de ' + app.nombre);
                        }
                      });
                  });
                }
              });
          });
          this.modalReferenceloading.close();
        }
      });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, {
      backdrop: 'static',
      size: 'sm',
      keyboard: false,
      centered: true,
    });
    this.seleccionados = [];
  }

}
