import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    dataUsuarios: Usuario[];
    interval: number;

    constructor(private usuariosService: UsuariosService, 
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
            this.refreshData();
      }

      refreshData(){
        this.usuariosService.getAll()
        .pipe(first())
        .subscribe(users =>
          this.dataUsuarios = users
        );
    }
    
      ngOnInit(): void {
        this.menuService.titulo = this.translateService.instant('menu.inicio');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('menu.inicio');
        });
      }
}