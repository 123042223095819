export class App {
    id: number;
    nombre: string;
    descripcion: string;
    imageUrl:string;
    avatarUrl:string;
    isActivaApi:boolean;
    isActivaDB:boolean;
    numUsuarios:number;
    entryPoint:string;
    apiKey:string;

    updateFoto:boolean;
}