import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@app/_models/app';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { Registro } from '@app/_models/registro';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


const baseUrl = `${environment.apiUrl}/noticia`;

@Injectable({
  providedIn: 'root'
})
export class PublicacionesService {

  constructor(private http: HttpClient) {}

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    ); //idUser no necesario
  }

  public getPublicacionesFromEnte(): Observable<any> {
    return this.http.get(baseUrl + '/getall');
  }

  public getPublicacionWithLang(
    idNoticia: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/get/' + idNoticia + '/' + lang);
  }

  public loadCategorias(ididioma: string, idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/categorias/' + idOrg + '/' + ididioma);
  }
  public getCategoriaById(idCategoria: number, lang: number): Observable<any> {
    console.log('');
    return this.http.get(baseUrl + '/categorias/' + idCategoria + '/' + lang);
  }

  public createPublicacion(noticia: Noticia): Observable<any> {
    noticia.fecha=new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      baseUrl + '/createw/',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public sendPublicacionToApp(noticia: Noticia,app:App): Observable<any> {

    //noticia.fecha=new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      app.entryPoint + '/noticiaext/createw/',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updatePublicacionToApp(noticia: Noticia,app:App): Observable<any> {

    //noticia.fecha=new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      app.entryPoint + '/noticiaext/updateext/',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  
  
  public actualizarPublicacion(noticia: Noticia): Observable<any> {

    noticia.fecha=new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      baseUrl + '/updateweb',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public addTraduccionesNoticia(listaTraducciones, idNoticia): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/addtradsw/' + idNoticia.toString(),
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public addTraduccionesNoticiaApp(listaTraducciones:NoticiaTraduccion[], idNoticia:number,app:App): Observable<any> {
    console.log('Enviando Traducciones App...');
 

    return this.http.post(
      app.entryPoint + '/noticiaext/addtrads/'+ idNoticia,
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }



  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public deleteExt(
    idNoticia: number,app:App
  ): Observable<any> {
    return this.http.get(app.entryPoint + '/noticiaext/deleteext/' + idNoticia);
  }



  public registrarPublicacionesApps(listaRegistros: Registro): Observable<any> {

    return this.http.post(
      baseUrl + '/registrar',
      JSON.stringify(listaRegistros),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getRegistroPublicacion(idPublicacion:number): Observable<any> {
    return this.http.get(baseUrl + '/registro/' + idPublicacion);
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }
}
