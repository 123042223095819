import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from "@app/_models/app";
import { AlertService } from '@app/_services';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-aplicaciones',
  templateUrl: './aplicaciones.component.html',
})
export class AplicacionesComponent implements OnInit {
  listaApps: App[] = [];
  listaAppsFilter: App[] = [];
  listaSeleccionados: App[] = [];

  constructor(
    public router: Router,
    public appSerivce:AplicacionesService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.appSerivce.getAllApps().subscribe((result) => {
      console.log(result);
          this.listaApps = result.data;
          this.listaAppsFilter = this.listaApps;

          this.listaApps.forEach(app => {
            console.log('Consultando '+app.nombre+': ');
            this.appSerivce.checkConnection(app).subscribe((result) => {
              console.log(result);
                app.isActivaApi=result.isActivaApi;
                app.isActivaDB=result.isActivaDB;
                console.log('Numero de usuarios de '+app.nombre+': '+result.code);
              });
            });
          });
      }
    


    //Recorrer lista de apps consultando la prueba de conexión a la api e ir asignando isActivaApi isActivaDB a cada App
    //Hacer que esa consulta sea obtener el número de usuarios en BD y mostrar el número de usuarios que tiene cada App debajo de los estados

  handleFilter(query: string) {
    if (query.length > 1) {
      this.listaAppsFilter = this.listaApps.filter(
        (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    } else if (query.length == 0) {
      this.listaAppsFilter = this.listaApps;
    }
  }

  handle_navigate_crear_app(){
    this.router.navigate(['aplicaciones-crear']);
  }

  handle_administrar_app(idApp){
    console.log('Administrar');
    this.router.navigate(['aplicaciones-editar',{idApp:idApp}]);
  }

  handle_publicaciones(){
    this.router.navigate(['publicaciones']);
  }
  handle_eventos(){
    this.router.navigate(['eventos']);
  }
  handle_encuestas(){
    this.router.navigate(['encuestas']);
  }
}
