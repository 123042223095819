import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { not } from '@angular/compiler/src/output/output_ast';
import {
  Encuesta,
  EncuestaModel,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { App } from '@app/_models/app';
import { Registro } from '@app/_models/registro';


const baseUrl = `${environment.apiUrl}/encuesta`;

@Injectable({
  providedIn: 'root'
})
export class EncuestasService {
  constructor(private http: HttpClient) {}

  public getAllEncuestas(lang: Number): Observable<any> {
    return this.http.get(baseUrl + '/getall/' + lang);
  }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    ); //idUser no necesario
  }

  public getEncuestasByEnte(idOrg: string, lang: number): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/encuesta/getall/' + idOrg + '/' + lang
    ); //idUser no necesario
  }

  public getEmcuestaByIdLang(
    idEncuesta: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/get/' + idEncuesta + '/' + lang); //idUser no necesario
  }

  public getOpcionesFromEncuesta(
    idEncuesta: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/opciones/' + idEncuesta + '/' + lang);
  }


  public crearEncuesta(myModel: EncuestaModel): Observable<any> {

    myModel.encuesta.fecha_inicio = new Date(this.dateToYYYYMMDDtHHmmSSz(myModel.encuesta.fecha_inicio));
    myModel.encuesta.fecha_fin = new Date(this.dateToYYYYMMDDtHHmmSSz(myModel.encuesta.fecha_fin));

    return this.http.post(
      baseUrl + '/crearw/',
      JSON.stringify(myModel),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public sendEncuestaToApp(myModel: EncuestaModel,app:App): Observable<any> {


    return this.http.post(
      app.entryPoint + '/encuestaext/crearw/',
      JSON.stringify(myModel),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public addTraduccionesEvento(listaTraducciones, idEvento): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/addtradsw/' + idEvento.toString(),
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarEncuesta(myEncuesta: Encuesta): Observable<any> {
    //Función para que mantanga las fechas en Local en vez de transformar automaticamente en UTC
    var replacer = function (key, value) {
      if (this[key] instanceof Date) {
        return this.dateToYYYYMMDDtHHmmSSz(this[key]);
      }

      return value;
    };
    let fecha:string = this.dateToYYYYMMDDtHHmmSSz(myEncuesta.fecha_inicio);

    console.log('Actualizando Encuesta...');
    console.log(myEncuesta);
    return this.http.post(
      baseUrl + '/update/',
      {
        id:myEncuesta.id,
        fecha_inicio: this.dateToYYYYMMDDtHHmmSSz(myEncuesta.fecha_inicio),
        fecha_fin: this.dateToYYYYMMDDtHHmmSSz(myEncuesta.fecha_fin),
        idIdioma:myEncuesta.idIdioma,
        titulo:myEncuesta.titulo,
        descripcion:myEncuesta.descripcion,
        listaOpciones:myEncuesta.listaOpciones,
        num_opciones_seleccion:myEncuesta.num_opciones_seleccion,
        saldo:myEncuesta.saldo,
        tipo:myEncuesta.tipo,
        showResults:myEncuesta.showResults,
        isMembersOnly:myEncuesta.isMembersOnly,
        idOrg:myEncuesta.idOrg,
        idCategoria:myEncuesta.idCategoria,
        edadMax:myEncuesta.edadMax,
        edadMin:myEncuesta.edadMin,
        urlFoto:'',

      }
    );
  }


  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public deleteExt(
    idNoticia: number,app:App
  ): Observable<any> {
    return this.http.get(app.entryPoint + '/encuestaext/deleteext/' + idNoticia);
  }



  public registrarPublicacionesApps(registro: Registro): Observable<any> {
    console.log('[S] Enviando registro]');
    return this.http.post(
      baseUrl + '/registrar',
      JSON.stringify(registro),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
    
  }

  public updateEncuestaToApp(myEncuesta: Encuesta,app:App): Observable<any> {

    return this.http.post(
      app.entryPoint + '/encuestaext/updateext/',
      JSON.stringify(myEncuesta),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  

  public getRegistroPublicacion(idPublicacion:number): Observable<any> {
    return this.http.get(baseUrl + '/registro/' + idPublicacion);
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }

}
