import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, MenuService } from '@app/_services';
import { App } from '@app/_models/app';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { Registro } from '@app//_models/registro';

@Component({
  selector: 'app-publicacion-crear',
  templateUrl: './publicacion-crear.component.html',
})
export class PublicacionCrearComponent implements OnInit {
  //Listas

  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaRegistros: Registro[] = [];
  //Traducciones
  listaTraduccionesEnviar: NoticiaTraduccion[] = [];
  //Data
  titulo: string = '';
  cuerpo: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  isProgramada: boolean = false;
  isMembersOnly: boolean = false;
  myNoticia: Noticia = new Noticia();
  filePath: string;
  public fecha: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';
  listaApps: App[] = [];
  listaAppsFilter: App[] = [];
  listaSeleccionados: App[] = [];

  lang;
  private translate: TranslateService;
  idOrg: string;

  constructor(
    private publicacionesService: PublicacionesService,
    public appSerivce: AplicacionesService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new NoticiaTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.appSerivce.getAllApps().subscribe((result) => {
      console.log(result);
      this.listaApps = result.data;
      this.listaAppsFilter = this.listaApps;
    });
  }

  loadIdiomas(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando idiomas de :' + value.id);
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }

  btnCrearPublicacion() {
    if (this.listaSeleccionados.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.erroraddapp')
      );
      return;
    }
    console.log('Creando Publicacion');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: NoticiaTraduccion = new NoticiaTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.titulo = seleccionado.titulo;
        trad.cuerpo = seleccionado.cuerpo;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myNoticia.idCategoria = 1;
    this.myNoticia.categoria = 'Patrocinado';
    this.myNoticia.author = 'Patrocinado';
    this.myNoticia.idOrg = '00000000-0000-0000-0000-000000000000';
    this.myNoticia.activa = true;
    this.myNoticia.isVip = this.isMembersOnly;
    this.myNoticia.programarPublicacion = this.isProgramada;
    if (this.isProgramada) this.myNoticia.fecha = new Date(this.fecha);
    this.myNoticia.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base64

    this.publicacionesService
      .createPublicacion(this.myNoticia)
      .subscribe((result) => {
        if (!result.error) {
          let idNoticiaPatrocinador: number = result.codigo;
          this.myNoticia.idNoticiaPatrocinador = idNoticiaPatrocinador;
          this.publicacionesService
            .addTraduccionesNoticia(this.listaTraduccionesEnviar, result.codigo)
            .subscribe((resultTrads) => {
              if (!resultTrads.error) {
                this.alertService.success(
                  this.translateService.instant('publicaciones.success')
                );

                console.log('Enviando a apps');
                this.listaSeleccionados.forEach((app) => {
                  console.log('Enviando Noticia a: ' + app.nombre);
                  this.publicacionesService
                    .sendPublicacionToApp(this.myNoticia, app)
                    .subscribe((resultapp) => {
                      console.log('Resultado APP '+app.nombre);
                      console.log(resultapp);
                      this.publicacionesService
                        .addTraduccionesNoticiaApp(
                          this.listaTraduccionesEnviar,
                          resultapp.codigo,
                          app
                        )
                        .subscribe((resultapptrads) => {
                          console.log(
                            'Resultado Send Traducciones '+app.nombre+" :" + resultTrads.error
                          );
                          console.log(resultapptrads);
                          let regTmp: Registro = new Registro();
                          regTmp.error = false;
                          regTmp.idApp = app.id;
                          regTmp.idPublicacion = idNoticiaPatrocinador;
                          if(resultapptrads.error)regTmp.error = true;
                          //Enviar registro de publicaciones
                          console.log('Enviando Registro '+app.nombre);
                          this.publicacionesService
                            .registrarPublicacionesApps(regTmp)
                            .subscribe((resultreg) => {
                              console.log(
                                'Lista Publicaciones a App '+app.nombre+' registrada'
                              );
                              console.log(this.listaRegistros.length);
                            });
                        });
                    });
                });

                return;
              } else {
                this.alertService.error(
                  this.translateService.instant('publicaciones.error')
                );
                return;
              }
            });
        } else {
          this.alertService.error(
            this.translateService.instant('publicaciones.error')
          );
          return;
        }
      });

    //Enviar Traducciones
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.titulo.length <= 0 ||
      this.cuerpo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.titulo = this.titulo;
          x.cuerpo = this.cuerpo;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['publicaciones']);
  }

  handleFilter(query: string) {
    if (query.length > 1) {
      this.listaAppsFilter = this.listaApps.filter(
        (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    } else if (query.length == 0) {
      this.listaAppsFilter = this.listaApps;
    }
  }
}
