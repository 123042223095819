import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  Encuesta,
  EncuestaModel,
  EncuestaOpcion,
  EncuestaOpcionTraduccion,
  EncuestaTipo,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { AlertService } from '@app/_services';
import { EncuestasService } from '@app/_services/encuestas.service';
import { EventosService } from '@app/_services/eventos.service';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { App } from '@app/_models/app';
import { Registro } from '@app/_models/registro';

@Component({
  selector: 'app-encuestas-crear',
  templateUrl: './encuestas-crear.component.html',
})
export class EncuestasCrearComponent implements OnInit {
  //Listas

  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTiposEncuesta: EncuestaTipo[] = [];
  listaTraducciones: Idioma[] = [];
  listaOpciones: EncuestaOpcion[] = [];
  listaTraduccionesOpcionesEnviar: EncuestaOpcionTraduccion[] = [];

  listaApps: App[] = [];
  listaAppsFilter: App[] = [];
  listaSeleccionados: App[] = [];
  //Traducciones
  listaTraduccionesEnviar: EncuestaTraduccion[] = [];
  //Data
  titulo: string = '';
  cuerpo: string = '';
  selectedIdioma: Idioma;
  selectedTipo: EncuestaTipo = new EncuestaTipo(1, '', 'Multiseleccion');
  edadMin: number = 0;
  edadMax: number = 120;
  showResults: boolean;
  isMembersOnly: boolean;
  numOpcionesSeleccionables: number = 1;
  numOpciones: number = 1;
  isPuntuada: boolean;
  saldo: number = 0;
  isBusy: boolean = false;

  myEncuesta: Encuesta = new Encuesta();
  public fechaInicio: Date = new Date();
  public fechaFin: Date = new Date();
  public horaInicio: Date = new Date();
  public horaFin: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  lang;
  private translate: TranslateService;

  constructor(
    private encuestaService: EncuestasService,
    private idiomaService: IdiomasService,
    private appService: AplicacionesService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('encuestas.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new EncuestaTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);

        //Opciones
        let opcion: EncuestaOpcionTraduccion = new EncuestaOpcionTraduccion();
        opcion.idIdioma = x.codigo;
        let listaTmp: EncuestaOpcion[] = [];
        opcion.trads = listaTmp;
        this.listaTraduccionesOpcionesEnviar.push(opcion);
      });
    });
    //Get Apps
    this.appService.getAllApps().subscribe((result) => {
      console.log(result);
      this.listaApps = result.data;
      this.listaAppsFilter = this.listaApps;
    });
    //Tipos de Encuesta
    let tmpTipo: EncuestaTipo = new EncuestaTipo(
      1,
      this.translate.instant('encuestas.tipo_multi'),
      'Multiseleccion'
    );
    this.listaTiposEncuesta.push(tmpTipo);
    tmpTipo = new EncuestaTipo(
      2,
      this.translate.instant('encuestas.tipo_propuesta'),
      'ConPropuesta'
    );
    this.listaTiposEncuesta.push(tmpTipo);
    tmpTipo = new EncuestaTipo(
      3,
      this.translate.instant('encuestas.tipo_sino'),
      'SiNo'
    );
    this.listaTiposEncuesta.push(tmpTipo);
    tmpTipo = new EncuestaTipo(
      4,
      this.translate.instant('encuestas.tipo_puntos'),
      'PorPuntos'
    );
    this.listaTiposEncuesta.push(tmpTipo);
    tmpTipo = new EncuestaTipo(
      5,
      this.translate.instant('encuestas.tipo_estrellas'),
      'Estrellas'
    );
    this.listaTiposEncuesta.push(tmpTipo);
    //Añadimos una opcion por defecto como mínimo
    let tmpOpcion: EncuestaOpcion = new EncuestaOpcion();
    tmpOpcion.id = this.listaOpciones.length;
    tmpOpcion.opcion = '';
    tmpOpcion.puntuacion = 0;
    this.listaOpciones.push(tmpOpcion);
  }

  loadIdiomas(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando idiomas de :' + value.id);
    this.idiomaService.getIdiomasFromEnte(value.id).subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }

  checkTipoEncuesta(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Tipo Seleccionada :' + value.id);
    this.isPuntuada = value.id == 4; //4 = Puntuada
  }

  btnCrearEncuesta() {
    console.log('Creando Encuesta');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.errortrads')
      );
      return;
    }
    if (this.fechaInicio > this.fechaFin) {
      this.alertService.warn(
        this.translateService.instant('encuestas.fechaerror')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    this.isBusy = true;
    let seleccionado: EncuestaTraduccion = new EncuestaTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.titulo = seleccionado.titulo;
        trad.cuerpo = seleccionado.cuerpo;
      }
    });
    //Opciones
    let seleccionadoOpciones: EncuestaOpcion[] = [];
    let encontradoOpcion: boolean = false;
    this.listaTraduccionesOpcionesEnviar.forEach((trad) => {
      if (trad.filled && !encontradoOpcion) {
        seleccionadoOpciones = trad.trads.map((x) => Object.assign({}, x));
        encontradoOpcion = true;
        return;
      }
    });
    //Opciones Fill Unfilled
    console.log(this.listaTraduccionesOpcionesEnviar);
    this.listaTraduccionesOpcionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        seleccionadoOpciones.forEach((element) => {
          element.idIdioma = trad.idIdioma;
        });
        trad.trads = seleccionadoOpciones.map((x) => Object.assign({}, x));
      }
    });

    //console.log(this.listaTraduccionesEnviar);
    console.log(this.listaTraduccionesOpcionesEnviar);

    // EnviarNoticia
    this.myEncuesta.idCategoria = 1;
    this.myEncuesta.categoria = 'Patrocinado';
    this.myEncuesta.idOrg = '00000000-0000-0000-0000-000000000000';
    this.myEncuesta.isMembersOnly = this.isMembersOnly;
    this.myEncuesta.saldo = this.saldo;
    this.myEncuesta.num_opciones_seleccion = this.numOpcionesSeleccionables;
    this.myEncuesta.showResults = this.showResults;
    this.myEncuesta.edadMax = this.edadMax;
    this.myEncuesta.edadMin = this.edadMin;
    console.log('Configurando Fechas...');
    this.myEncuesta.fecha_inicio = new Date(
      this.fechaInicio.getFullYear(),
      this.fechaInicio.getMonth(),
      this.fechaInicio.getDate(),
      this.horaInicio.getHours(),
      this.horaInicio.getMinutes(),
      this.horaInicio.getSeconds()
    );
    this.myEncuesta.fecha_fin = new Date(
      this.fechaFin.getFullYear(),
      this.fechaFin.getMonth(),
      this.fechaFin.getDate(),
      this.horaFin.getHours(),
      this.horaFin.getMinutes(),
      this.horaFin.getSeconds()
    );
    this.myEncuesta.tipo = this.selectedTipo.tipo;

    let myModel: EncuestaModel = new EncuestaModel();
    myModel.encuesta = this.myEncuesta;
    myModel.encuestaTraducciones = this.listaTraduccionesEnviar;
    myModel.opciones = this.listaTraduccionesOpcionesEnviar[0].trads;
    myModel.opcionesTraducciones = this.listaTraduccionesOpcionesEnviar;

    this.encuestaService.crearEncuesta(myModel).subscribe((result) => {
      if (!result.error) {
        let idEncuestaPatrocinador: number = result.codigo;
        this.myEncuesta.idEncuestaPatrocinador = idEncuestaPatrocinador;
        this.alertService.success(
          this.translateService.instant('encuestas.success')
        );
        //Enviar Apps
        console.log('Enviando a Apps');
        this.listaSeleccionados.forEach((app) => {
          console.log('Enviando a ' + app.nombre);
          this.encuestaService.sendEncuestaToApp(myModel,app).subscribe((appresult)=>{
            console.log('Resultado enviar encuesta a '+app.nombre);
            console.log(appresult);
            console.log('Enviando Registro '+app.nombre);
            let regTmp: Registro = new Registro();
            regTmp.error = false;
            regTmp.idApp = app.id;
            regTmp.idPublicacion = idEncuestaPatrocinador;
            if(appresult.error)regTmp.error = true;
            console.log('Vamos');
            this.encuestaService
              .registrarPublicacionesApps(regTmp)
              .subscribe((resultreg) => {
                console.log(
                  'App '+app.nombre+' registrada encuesta'
                );
                console.log(resultreg);
              });
          });
        //End Enviar Apps
      });
      this.isBusy=false;
      } else {
        this.isBusy = false;
        this.alertService.error(
          this.translateService.instant('encuestas.error')
        );
        return;
      }
    });

    //Enviar Traducciones
  }

  btnAddOption() {
    if (this.listaTraducciones.length > 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.alerteditopcion')
      );
      return;
    }
    this.numOpciones++;
    let tmpOpcion: EncuestaOpcion = new EncuestaOpcion();
    tmpOpcion.id = this.listaOpciones.length;
    tmpOpcion.opcion = '';
    tmpOpcion.puntuacion = 0;
    this.listaOpciones.push(tmpOpcion);
  }

  btnDeleteOption(opcion: EncuestaOpcion) {
    if (this.listaTraducciones.length > 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.alerteditopcion')
      );
      return;
    }
    if (this.listaOpciones.length <= 1) {
      return;
    }
    this.numOpciones--;
    this.listaOpciones = this.listaOpciones.filter((x) => x != opcion);
    if (this.numOpcionesSeleccionables > this.listaOpciones.length)
      this.numOpcionesSeleccionables--;
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.titulo.length <= 0 ||
      this.cuerpo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('eventos.validacion')
        );
        return;
      }
    }
    console.log(this.listaOpciones.length);
    let opFilled = true;
    this.listaOpciones.forEach((opcion) => {
      if (opcion == null || opcion.opcion == null || opcion.opcion.length < 1)
        opFilled = false;
    });
    if (!opFilled) {
      this.alertService.info(
        this.translateService.instant('eventos.validacion')
      );

      return;
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);
      //Traducciones Encuesta
      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.titulo = this.titulo;
          x.cuerpo = this.cuerpo;
        }
      });
      //Traducciones Opciones
      this.listaTraduccionesOpcionesEnviar.forEach((trad) => {
        if (this.selectedIdioma.codigo == trad.idIdioma) {
          this.listaOpciones.forEach((opcion) => {
            let opTmp: EncuestaOpcion = new EncuestaOpcion();
            opTmp.idIdioma = this.selectedIdioma.codigo;
            opTmp.opcion = opcion.opcion;
            opTmp.puntuacion = opcion.puntuacion;
            trad.trads.push(opTmp);
          });
          trad.filled = true;
        }
      });

      //End Traducciones Opciones
    } else {
      console.log('Idioma ya añadido');
    }
    //console.log(this.listaTraduccionesOpcionesEnviar);
    //console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
    //Opciones
    this.listaTraduccionesOpcionesEnviar.forEach((x) => {
      if (x.idIdioma == idioma.codigo) {
        x.filled = false;
      }
    });
  }

  btnCancelar() {
    this.router.navigate(['encuestas']);
  }

  handleFilter(query: string) {
    if (query.length > 1) {
      this.listaAppsFilter = this.listaApps.filter(
        (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    } else if (query.length == 0) {
      this.listaAppsFilter = this.listaApps;
    }
  }
}
