<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "encuestas.titulo_edit" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <!--Lista tipos Encuesta-->
              <div class="clearfix">
                <kendo-label text="{{ 'encuestas.lbl_tipo' | translate }}">
                </kendo-label>
              </div>
              {{ myEncuesta.tipo }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <!--Lista idiomas-->
              <kendo-label text="{{ 'eventos.idiomas' | translate }}">
              </kendo-label>
              <div class="caja">
                <kendo-dropdownlist
                  #dropdownI
                  [data]="listaIdiomas"
                  textField="nombre"
                  valueField="codigo"
                  [(ngModel)]="selectedIdioma"
                ></kendo-dropdownlist>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'encuestas.ph_titulo' | translate }}"
            [clearButton]="true"
            [(ngModel)]="titulo"
          >
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton look="clear" icon="calendar"></button>
            </ng-template>
          </kendo-textbox>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'encuestas.lbl_descripcion' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="cuerpo"
            >
            </textarea>
          </kendo-label>
        </div>

        <!-- Selección Enviar App-->
        <kendo-multiselect
          [autoClose]="true"
          [filterable]="true"
          (filterChange)="handleFilter($event)"
          [data]="listaAppsFilter"
          textField="nombre"
          valueField="id"
          [(ngModel)]="listaSeleccionados"
          placeholder="Selecciona las Apps a las que enviar el contenido"
        ></kendo-multiselect>

        <div class="form-group">
          <!--Boton Actualizar-->
          <button
            type="button"
            [disabled]="isBusy"
            class="btn btn-success btn-sm mr-1"
            (click)="btnActualizarEncuesta()"
          >
            {{ "encuestas.actualizar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "encuestas.titulo_edit" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <div *ngIf="isPuntuada">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'encuestas.lbl_saldo' | translate }}"
            ></kendo-label>
            <kendo-numerictextbox
              placeholder="Valor Distribuible"
              [(ngModel)]="saldo"
            >
            </kendo-numerictextbox>
          </div>
        </div>

        <div class="form-group">
          <!--Edad Max Min-->
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'encuestas.rangoedad' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-numerictextbox
              format="n"
              [style.marginBottom.px]="10"
              [(value)]="edadMin"
              [min]="0"
              [max]="120"
            ></kendo-numerictextbox>
            <kendo-numerictextbox
              format="n"
              [style.marginBottom.px]="10"
              [(value)]="edadMax"
              [min]="0"
              [max]="120"
            >
            </kendo-numerictextbox>
          </div>
        </div>

        <!-- Encuesta Fechas-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechaini' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaInicio">
            </kendo-datepicker>
            <kendo-timepicker
              format="HH:mm:ss"
              [(value)]="horaInicio"
            ></kendo-timepicker>
          </div>
        </div>
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechafin' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaFin">
            </kendo-datepicker>
            <kendo-timepicker
              format="HH:mm:ss"
              [(value)]="horaFin"
            ></kendo-timepicker>
          </div>
        </div>

        <div class="form-group">
          <!--CheckBoxes ShowResults && IsMembersOnly-->
          <div class="row">
            <div class="col">
              <kendo-label
                class="k-checkbox-label"
                text="{{ 'encuestas.showresults' | translate }}"
              ></kendo-label>
              <div class="caja">
                <input
                  type="checkbox"
                  [(ngModel)]="showResults"
                  kendoCheckBox
                />
              </div>
            </div>

            <div class="col">
              <kendo-label
                class="k-checkbox-label"
                text="{{ 'encuestas.membersonly' | translate }}"
              ></kendo-label>
              <div class="caja">
                <input
                  type="checkbox"
                  [(ngModel)]="isMembersOnly"
                  kendoCheckBox
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "encuestas.titulo_edit" | translate }}</h3>
      </div>
      <div class="card-body">
        <!--Opciones-->
        <div class="form-group anadir-opciones">
          <button
            kendoButton
            (click)="btnAddOption()"
            class="btn btn-success btn-anadir-opcion"
            icon="plus"
          ></button>

          <div class="linea-opcion" *ngFor="let opcion of listaOpciones">
            <div *ngIf="!opcion.borrar">
              <kendo-textbox
                placeholder="Opcion"
                [clearButton]="true"
                [(ngModel)]="opcion.opcion"
              >
              </kendo-textbox>

              <button
                type="button"
                class="btn btn-danger"
                (click)="btnDeleteOption(opcion)"
              >
                X
              </button>

              <kendo-numerictextbox
                *ngIf="isPuntuada"
                placeholder="Valor"
                [(ngModel)]="opcion.puntuacion"
              >
              </kendo-numerictextbox>
            </div>
          </div>
        </div>

        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'encuestas.lbl_numopciones' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-slider
              incrementTitle="Inc"
              decrementTitle="Dec"
              [min]="1"
              [max]="numOpciones"
              [(ngModel)]="numOpcionesSeleccionables"
            ></kendo-slider>
          </div>
          {{ numOpcionesSeleccionables }}
        </div>
      </div>
    </div>
  </div>
</div>
