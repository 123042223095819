import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@app/_models/app';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


const baseUrl = `${environment.apiUrl}/aplicacion`;

@Injectable({
  providedIn: 'root'
})
export class AplicacionesService {

  constructor(private http: HttpClient) { }

  public GetAppById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }
  public getAllApps(): Observable<any> {
    return this.http.get(
      baseUrl + '/all'
    ); 
  }

  public createApp(aplicacion: App): Observable<any> {

    return this.http.post(
      baseUrl + '/create/',
      JSON.stringify(aplicacion),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateApp(aplicacion: App): Observable<any> {

    return this.http.post(
      baseUrl + '/update/',
      JSON.stringify(aplicacion),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public checkConnection(app:App): Observable<any> {
    return this.http.get(app.entryPoint + '/noticiaext/checkconnection');
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  
}
