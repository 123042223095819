import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '@app/_models/app';
import { AlertService } from '@app/_services';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-app-editar',
  templateUrl: './app-editar.component.html',
})
export class AppEditarComponent implements OnInit {

  nombre: string;
  descripcion: string;
  imageUrl: string;
  avatarUrl: string;
  entryPoint: string;
  apiKey: string;

  filePath: string;
  imagenUrl:string;
  idApp:number;
  updateFoto:boolean;
  isBusy:boolean;

  constructor(
    public router: Router,
    public appSerivce:AplicacionesService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService,
    ) {

    }
    
    ngOnInit(): void {
      
    this.idApp = this.route.snapshot.params['idApp'];
    this.appSerivce.GetAppById(this.idApp).subscribe((result) => {
      console.log(result);
        this.nombre = result.data[0].nombre;
        this.descripcion = result.data[0].descripcion;
        this.entryPoint = result.data[0].entryPoint;
        this.apiKey = result.data[0].apiKey;
        this.imagenUrl=result.data[0].imageUrl;
        this.filePath=result.data[0].imageUrl;

      }
    );
  }

  btnActualizarApp() {
    if (
      this.nombre.length <= 0 ||
      this.descripcion.length <= 0 ||
      this.entryPoint.length <= 5 ||
      this.apiKey.length <= 0
    ) {
      return;
    }

    this.isBusy=true;
    //Actualizando App
    console.log('Updating...');
    console.log(this.filePath);
    let myApp:App=new App();
    myApp.id=this.idApp;
    myApp.nombre=this.nombre;
    myApp.descripcion=this.descripcion;
    myApp.entryPoint=this.entryPoint;
    myApp.apiKey=this.apiKey;
    if(this.filePath!=this.imagenUrl){
      console.log('Actuaizar foto=true.');
      myApp.updateFoto=true;
      myApp.imageUrl =  this.filePath.split(',')[1];
    }
    else{
      myApp.imageUrl =this.imagenUrl;
    }

    this.appSerivce.updateApp(myApp).subscribe((result) => {
      if(!result.error) {
        this.alertService.success(this.translateService.instant('aplicaciones.successupdate'), { keepAfterRouteChange: true });
        this.router.navigate(['aplicaciones']);
        this.isBusy = false;
      } else {
        this.alertService.error(this.translateService.instant('aplicaciones.errorupdate'));
        this.isBusy = false;
      }
      }
    );

  }

  btnCancelar() {
    this.router.navigate(['aplicaciones']);
  }



  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      console.log('Imagen cargada');
      this.updateFoto=true;
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
}
