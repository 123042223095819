import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '@app/_models/app';
import { AlertService } from '@app/_services';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-app-crear',
  templateUrl: './app-crear.component.html',
})
export class AppCrearComponent implements OnInit {
  nombre:string;
  descripcion:string;
  imageUrl:string;
  avatarUrl:string;
  entryPoint:string;
  apiKey:string;

  filePath: string;
  isBusy:boolean=false;

  constructor(
    public router: Router,
    public appSerivce:AplicacionesService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }


  btnCrearApp(){
    if(this.nombre.length<=0 || this.descripcion.length<=0 || this.entryPoint.length<=5 || this.apiKey.length<=0){
      
      return;
    }

    this.isBusy = true;
    //Creando App
    let myApp:App = new App();
    myApp.nombre=this.nombre;
    myApp.descripcion=this.descripcion;
    myApp.entryPoint=this.entryPoint;
    myApp.apiKey=this.apiKey;
    myApp.imageUrl='';
    myApp.avatarUrl='';
    myApp.imageUrl = this.filePath.split(',')[1];

    this.appSerivce.createApp(myApp).subscribe((result) => {
      if(!result.error) {
        this.alertService.success(this.translateService.instant('aplicaciones.success'), { keepAfterRouteChange: true });
        this.router.navigate(['aplicaciones']);
        this.isBusy = false;
      } else {
        this.alertService.error(this.translateService.instant('aplicaciones.errorcrear'));
        this.isBusy = false;
      }
      }
    );
    
  }

  btnCancelar(){
    this.router.navigate(['aplicaciones']);
  }
  
  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
