import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import { App } from '@app/_models/app';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
})
export class PublicacionesComponent implements OnInit {
  listaPublicaciones: Noticia[] = [];
  public seleccionados: number[] = [];
  listaApps: App[] = [];
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');

    this.publicacionesService.getPublicacionesFromEnte().subscribe((result) => {
      console.log(result);
      this.listaPublicaciones = result.data;
      console.log('Publicaciones cargadas: ' + this.listaPublicaciones.length);
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  onClickNuevo() {
    this.router.navigate(['publicaciones-crear']);
  }

  onClickEliminar(content) {
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, {
        backdrop: 'static',
        size: 'lg',
        keyboard: false,
        centered: true,
      });
    }
  }

  onClickEditar() {
    this.router.navigate([
      'publicaciones-editar',
      { idnoticia: this.seleccionados[0] },
    ]);
  }

  cellClick() {
    this.router.navigate([
      'publicaciones-editar',
      { idnoticia: this.seleccionados[0] },
    ]);
  }

  eliminarRegistro(contentloading) {
    console.log('Eliminar registro');
    let listaBorrar:number[]=[];
    listaBorrar = this.seleccionados;
    this.publicacionesService
      .delete({ codigos: this.seleccionados })
      .subscribe((data) => {
        if (data.error == false) {
          this.publicacionesService
            .getPublicacionesFromEnte()
            .subscribe((result) => {
              this.listaPublicaciones = result.data;
            });
          console.log('Borrando de Apps num: '+listaBorrar.length);
          //Borrando de Apps
          listaBorrar.forEach((idNoticia) => {
            console.log('Borrando Noticia: '+ idNoticia);
            this.publicacionesService
              .getRegistroPublicacion(idNoticia)
              .subscribe((resultTmp) => {
                console.log(resultTmp);
                this.listaApps = resultTmp.data;
                if (this.listaApps.length > 0) {
                  this.listaApps.forEach((app) => {
                    console.log('Borrando ' + app.nombre);
                    this.publicacionesService
                      .deleteExt(idNoticia, app)
                      .subscribe((resultDeleteExt) => {
                        if (!resultDeleteExt.error) {
                          console.log('Borrada de ' + app.nombre);
                        } else {
                          console.log('Error borrando de ' + app.nombre);
                        }
                      });
                  });
                }
              });
          });
          this.modalReferenceloading.close();
        }
      });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, {
      backdrop: 'static',
      size: 'sm',
      keyboard: false,
      centered: true,
    });
    this.seleccionados = [];
  }
}
