export class Encuesta {
    id: string;
    titulo: string;
    descripcion: string;
    categoria:string;
    idCategoria:number;
    created:Date;
    fecha_inicio:Date;
    fecha_fin:Date;
    idOrg:string;
    isMembersOnly:boolean;
    edadMax:number;
    edadMin:number;
    tipo:string;
    showResults:boolean;
    urlFoto:boolean;
    num_opciones_seleccion:number;
    saldo:number;
    idIdioma:number;
    filled:boolean;
    idEncuestaPatrocinador:number;
    listaOpciones:EncuestaOpcion[];
}

export class EncuestaTraduccion {
    id: string;
    titulo: string;
    cuerpo: string;
    idIdioma:number;
    nombreIdioma:string;
    idEncuesta:number;
    filled:boolean;
}

export class EncuestaOpcion {
    id: number;
    idEncuesta : number;
    idOrg:string;
    opcion:string;
    puntuacion:number;
    votos:number;
    indexvalor:number;
    Seleccionada:boolean;
    idIdioma:number;
    nuevo:boolean;
    borrar:boolean;
    filled:boolean;
}

export class EncuestaOpcionTraduccion {
    idIdioma:number;
    trads:EncuestaOpcion[];
    filled:boolean;
}

export class EncuestaModel {
    encuesta:Encuesta;
    encuestaTraducciones:EncuestaTraduccion[];
    opciones:EncuestaOpcion[];
    opcionesTraducciones:EncuestaOpcionTraduccion[];
}

//Tipos Encuesta
export class EncuestaTipo{
    id:number;
    nombre:string;
    tipo:string;

    constructor(id:number,tmpNombre:string,tmpTIpo:string){
        this.id=id;
        this.nombre=tmpNombre;
        this.tipo=tmpTIpo;
    }
}