import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
} from '@app/_services';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { EventosService } from '@app/_services/eventos.service';
import { EncuestasService } from '@app/_services/encuestas.service';

//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import {
  DropDownsModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';

//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { AplicacionesComponent } from './aplicaciones/aplicaciones.component';
import { AppCrearComponent } from './aplicaciones/app-crear/app-crear.component';
import { AppEditarComponent } from './aplicaciones/app-editar/app-editar.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { PublicacionCrearComponent } from './publicaciones/publicacion-crear/publicacion-crear.component';
import { PublicacionEditarComponent } from './publicaciones/publicacion-editar/publicacion-editar.component';
import { EventosComponent } from './eventos/eventos/eventos.component';
import { EventosCrearComponent } from './eventos/eventos-crear/eventos-crear.component';
import { EventosEditarComponent } from './eventos/eventos-editar/eventos-editar.component';
import { EncuestasComponent } from './encuestas/encuestas/encuestas.component';
import { EncuestasCrearComponent } from './encuestas/encuestas-crear/encuestas-crear.component';
import { EncuestasEditarComponent } from './encuestas/encuestas-editar/encuestas-editar.component';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    DetailsComponent,
    UpdateComponent,
    AplicacionesComponent,
    AppCrearComponent,
    AppEditarComponent,
    PublicacionesComponent,
    PublicacionCrearComponent,
    PublicacionEditarComponent,
    EventosComponent,
    EventosCrearComponent,
    EventosEditarComponent,
    EncuestasComponent,
    EncuestasCrearComponent,
    EncuestasEditarComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    IdiomasService,
    AplicacionesService,
    PublicacionesService,
    EventosService,
    EncuestasService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
