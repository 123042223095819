import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import { stringify } from '@angular/compiler/src/util';
import { App } from '@app/_models/app';

@Component({
  selector: 'app-publicacion-editar',
  templateUrl: './publicacion-editar.component.html',
})
export class PublicacionEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaApps: App[] = [];
  listaAppsFilter: App[] = [];
  listaSeleccionados: App[] = [];
  //Data

  titulo: string = '';
  cuerpo: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  isProgramada: boolean;
  isMembersOnly: boolean;
  myNoticia: Noticia = new Noticia();
  filePath: string;
  public fecha: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  lang;
  private translate: TranslateService;
  idOrg: string;
  idNoticia:number;

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.idNoticia = this.route.snapshot.params['idnoticia']
    this.idOrg = this.route.snapshot.params['idorg']
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      this.selectedIdioma=this.listaIdiomas[0];
      //LoadNoticia
      this.publicacionesService.getPublicacionWithLang(this.idNoticia,this.selectedIdioma.codigo).subscribe((result) => {
        console.log('Noticia result:');
        console.log(result.data);
        this.myNoticia=result.data[0];
        console.log('Noticia:');
        console.log(this.myNoticia);
        this.fecha=new Date(this.myNoticia.fecha);
        this.filePath=this.myNoticia.fotoUrl;
        this.titulo=this.myNoticia.titulo;
        this.cuerpo=this.myNoticia.cuerpo;
        this.isMembersOnly=this.myNoticia.privada;
        this.isProgramada=true;

        });

        //Load App Registradas en publicación
        this.publicacionesService.getRegistroPublicacion(this.idNoticia).subscribe((resultReg) => {
          console.log('Registro apps');
            console.log(resultReg.data);
            this.listaApps = resultReg.data;
            this.listaSeleccionados = resultReg.data;
        });

      });
  
  }

  loadNoticia(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    this.publicacionesService.getPublicacionWithLang(this.idNoticia,this.selectedIdioma.codigo).subscribe((result) => {
      console.log('Noticia result:');
      console.log(result.data);
      this.myNoticia=result.data[0];
      console.log('Noticia:');
      console.log(this.myNoticia);
      this.fecha=new Date(this.myNoticia.fecha);
      this.filePath=this.myNoticia.fotoUrl;
      this.titulo=this.myNoticia.titulo;
      this.cuerpo=this.myNoticia.cuerpo;
      this.isMembersOnly=this.myNoticia.privada;
      this.isProgramada=true;
      });
  }

  btnActualizarPublicacion() {
    console.log('Actualizando Publicacion');
    //Validación de los datos
      console.log(this.myNoticia);
    if ( this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

      this.myNoticia.titulo=this.titulo;
      this.myNoticia.cuerpo=this.cuerpo;
      this.myNoticia.idIdioma=this.selectedIdioma.codigo;
      this.myNoticia.fecha=new Date(this.fecha);
      this.myNoticia.privada= this.isMembersOnly;

      this.myNoticia.idCategoria=1;
      this.myNoticia.programarPublicacion=this.isProgramada;
      console.log('FOTO');
      console.log(this.myNoticia.fotoUrl);
      if(this.filePath!=this.myNoticia.fotoUrl){
        console.log('Obteniendo en BASE64...');
        this.myNoticia.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base
        this.myNoticia.updateFoto=true;
      }
      console.log('Actualizando noticia Patro');
      //Actualizar Noticia
      this.publicacionesService.actualizarPublicacion(this.myNoticia).subscribe((result) => {
          if(!result.error){
            this.alertService.success(
              this.translateService.instant('publicaciones.successupdate')
            );
            //Actualizar en apps
            console.log('Actualizando Apps');
            if(this.listaSeleccionados.length<=0) return;
            this.listaSeleccionados.forEach(app => {
              console.log('Actualizando '+app.nombre);
              this.publicacionesService.updatePublicacionToApp(this.myNoticia,app)
              .subscribe((resultUpdateApp)=>{
                console.log(app.nombre);
                console.log(resultUpdateApp);
                if(!resultUpdateApp.error){
                  console.log('Actualizada '+ app.nombre);
                }
                else{
                  console.log('Error al actualizar la app '+ app.nombre);
                }
              });
            });
          }
          else{
            this.alertService.error(
              this.translateService.instant('publicaciones.errorupdate')
            );
          }
      });

  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['publicaciones']);
  }

  handleFilter(query: string) {
    if (query.length > 1) {
      this.listaAppsFilter = this.listaApps.filter(
        (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    } else if (query.length == 0) {
      this.listaAppsFilter = this.listaApps;
    }
  }
}
