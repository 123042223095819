<div class="row">
  <div class="col-lg-4">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "eventos.tituloedit" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <!--Lista idiomas-->
          <div class="clearfix">
            <kendo-label text="{{ 'eventos.idiomas' | translate }}">
            </kendo-label>
          </div>
          <kendo-dropdownlist
            #dropdownI
            [data]="listaIdiomas"
            textField="nombre"
            valueField="codigo"
            [(ngModel)]="selectedIdioma"
          ></kendo-dropdownlist>
        </div>

        <div class="form-group">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'eventos.titulo_publi' | translate }}"
            [clearButton]="true"
            [(ngModel)]="titulo"
          >
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton look="clear" icon="calendar"></button>
            </ng-template>
          </kendo-textbox>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'eventos.cuerpo_publi' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="cuerpo"
            >
            </textarea>
          </kendo-label>
        </div>

        <div class="form-group">
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>

          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
        </div>

        <div class="form-group">
          <!-- Evento Fechas-->

          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.isallday' | translate }}"
          ></kendo-label>
          <div class="caja">
            <input type="checkbox" [(ngModel)]="isAllDay" kendoCheckBox />
          </div>
        </div>

        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechaini' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaInicio">
            </kendo-datepicker>
            <kendo-timepicker
              *ngIf="!isAllDay"
              format="HH:mm:ss"
              [(ngModel)]="horaInicio"
            ></kendo-timepicker>
          </div>
        </div>

        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechafin' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaFin">
            </kendo-datepicker>
            <kendo-timepicker
              *ngIf="!isAllDay"
              format="HH:mm:ss"
              [(ngModel)]="horaFin"
            ></kendo-timepicker>
          </div>
        </div>

        <!--Evento Recurrencia-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.recurrencia' | translate }}"
          ></kendo-label>

          <div class="caja">
            <input type="checkbox" [(ngModel)]="isRecurrente" kendoCheckBox />

            <kendo-dropdownlist
              *ngIf="isRecurrente"
              #dropdown
              [data]="listaRecurrencias"
              class="ml-1"
              textField="nombre"
              valueField="id"
              [(ngModel)]="selectedRecurrencia"
            ></kendo-dropdownlist>
          </div>
        </div>

        <!-- Selección Enviar App-->
        <kendo-multiselect
          [autoClose]="true"
          [filterable]="true"
          (filterChange)="handleFilter($event)"
          [data]="listaAppsFilter"
          textField="nombre"
          valueField="id"
          [(ngModel)]="listaSeleccionados"
          placeholder="Selecciona las Apps a las que enviar el contenido"
        ></kendo-multiselect>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            kendoButton
            (click)="bntUpdateEvento()"
            class="btn btn-success btn-sm mr-1"
            [disabled]="isBusy"
          >
            {{ "eventos.actualizar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
