import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { not } from '@angular/compiler/src/output/output_ast';
import { Evento,EventoModel, Recurrencia } from '@app/_models/evento';
import { TranslateService } from '@ngx-translate/core';
import { App } from '@app/_models/app';
import { Registro } from '@app/_models/registro';

const baseUrl = `${environment.apiUrl}/agenda`;

@Injectable({
  providedIn: 'root'
})
export class EventosService {
  constructor(private http: HttpClient,
    private translateService: TranslateService) { }



    public getAll(): Observable<any> {
      return this.http.get(baseUrl);
    }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public getPublicacionesFromEnte(idOrg:string):Observable<any> {
    return this.http.get(baseUrl+"/ente/"+idOrg );
  }

  public getEventoWithLang(idEvento:number,lang:number):Observable<any> {
    return this.http.get(baseUrl+"/get/"+idEvento+"/"+lang);
  }

  public crearEvento(eventoModel:EventoModel):Observable<any> {

    console.log('Creando Evento...');

    eventoModel.evento.fechaInciio= new Date(this.dateToYYYYMMDDtHHmmSSz(eventoModel.evento.fechaInciio));
    eventoModel.evento.fechaFin= new Date(this.dateToYYYYMMDDtHHmmSSz(eventoModel.evento.fechaFin));

    return this.http.post(baseUrl+"/crearw/",JSON.stringify(eventoModel),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
}

public actualizarEvento(myEvento:Evento):Observable<any> {

  myEvento.fechaInciio= new Date(this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaInciio));
  myEvento.fechaFin= new Date(this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaFin));
  console.log('Actualizando Evento Patro...');
  console.log(myEvento);
  return this.http.post(baseUrl+"/updateweb/",JSON.stringify(myEvento),
  {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      charset: 'utf-8',
    }),
    withCredentials: true,
  }
);
}

public sendPublicacionToApp(eventoModel:EventoModel,app:App):Observable<any> {


  return this.http.post(
    app.entryPoint + '/eventoext/crearw/',
    JSON.stringify(eventoModel),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
}


public updatePublicacionToApp(myEvento: Evento,app:App): Observable<any> {


  console.log('Actualizando envento app');
  console.log(myEvento);
  return this.http.post(
    app.entryPoint + '/eventoext/updateext/',
    JSON.stringify(myEvento),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
}

// public updatePublicacionToApp2(myEvento: Evento,app:App): Observable<any> {
//   console.log('Prueba');
//   return this.http.post(
//     app.entryPoint + '/eventoext/updateext/', {
//     id: myEvento.id,
//     fechaInciio: this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaInciio),
//     fechaFin: this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaFin),
//   });
// }


  public addTraduccionesEvento(listaTraducciones,idEvento):Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(baseUrl+"/addtradsw/"+idEvento.toString(),JSON.stringify(listaTraducciones),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public getRegistroPublicacion(idPublicacion:number): Observable<any> {
    return this.http.get(baseUrl + '/registro/' + idPublicacion);
  }

  
  public registrarPublicacionesApps(listaRegistros: Registro): Observable<any> {

    return this.http.post(
      baseUrl + '/registrar',
      JSON.stringify(listaRegistros),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public deleteExt(
    idNoticia: number,app:App
  ): Observable<any> {
    return this.http.get(app.entryPoint + '/eventoext/deleteext/' + idNoticia);
  }

 
  getRecurrencias():Recurrencia[]{
    let listaRecurrencias: Recurrencia[] = [];
    let r1: Recurrencia = new Recurrencia();
    r1.id = 1;
    r1.nombre = this.translateService.instant('eventos.recurrenciaAnual');
    let r2: Recurrencia = new Recurrencia();
    r2.id = 2;
    r2.nombre = this.translateService.instant('eventos.recurrenciaMensual');
    let r3: Recurrencia = new Recurrencia();
    r3.id = 3;
    r3.nombre = this.translateService.instant('eventos.recurrenciaSemanal');
    let r4: Recurrencia = new Recurrencia();
    r4.id = 4;
    r4.nombre = this.translateService.instant('eventos.recurrenciaDiaria');
    listaRecurrencias.push(r1);
    listaRecurrencias.push(r2);
    listaRecurrencias.push(r3);
    listaRecurrencias.push(r4);
    console.log(listaRecurrencias);
    return listaRecurrencias;
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }
  
}



